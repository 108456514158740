import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const CpmAdmin = () => {
  return (
    <MicroFrontEnd name="CpmAdmin" src={apiConfig.microFrontEnds.CpmAdmin} />
  )
}

export default CpmAdmin
