import * as React from 'react'
import { useAuth } from '@praxis/component-auth'

interface UserInfoProps {
  nameType: 'initials' | 'full'
}

export const UserInfo: React.FC<UserInfoProps> = ({ nameType }) => {
  const auth = useAuth()
  try {
    if (
      !auth?.session?.userInfo?.firstName[0] ||
      !auth.session.userInfo.lastName[0]
    ) {
      throw new Error()
    }
    if (nameType === 'full') {
      return (
        <span>
          {`${auth.session.userInfo.firstName} ${auth.session.userInfo.lastName}`}
        </span>
      )
    }
    if (nameType === 'initials') {
      return (
        <span>
          {`${auth.session.userInfo.firstName[0]}${auth.session.userInfo.lastName[0]}`}
        </span>
      )
    }
    return null
  } catch {
    return (
      <span role="img" aria-label="Robot">
        🤖
      </span>
    )
  }
}

export default UserInfo
