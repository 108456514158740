import {
  CATALOG_DETAILS_LABEL,
  CATALOG_DETAILS_PATH,
  CATALOG_IMAGE_BULK_UPLOAD_LABEL,
  CATALOG_ITEM_DETAILS_LABEL,
  CATALOG_ITEM_MULTI_EDIT_LABEL,
  CATALOG_ITEM_SEARCH_LABEL,
  CATALOG_PARENT_LABEL,
  CATALOG_UPDATES_LABEL,
  HOME_LABEL,
  HZN_SOURCING_GROUPS_LABEL,
  HZN_SOURCING_GROUPS_MULTI_EDIT_LABEL,
  INDIRECT_ITEM_PARENT_LABEL,
  INDIRECT_ITEM_SEARCH_LABEL,
  UNSPSC_LOOKUP_TOOL_LABEL,
  INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD_LABEL,
  INDIRECT_ITEMS_MULTI_EDIT_LABEL,
  ITEM_ATTR_REPORT_LABEL,
  LOCATIONS_SEARCH_LABEL,
  NRI_DETAILS_LABEL,
  PROGRAM_LABEL,
  PROGRAM_LIST_LABEL,
  PROGRAM_SUB_LABEL,
  PURCHASE_LABEL,
  PURCHASE_PATH,
  CONTRACT_EXPIRATION_LABEL,
  VA_GRID_LIST_LABEL,
  VA_EDIT_LABEL,
  ZOL_PLANNED_BOM_LABEL,
  ZOL_PUBLISHED_BOMS_LABEL,
  DEMAND_PLANNING_PARENT_LABEL,
  NRI_ITEM_CREATE_LABEL,
  NRI_CREATE_LABEL,
  INDIRECT_ITEM_REPORTS,
  INDIRECT_ITEMS_VARIATIONS_LABEL,
  CATALOG_ITEM_SEARCH_ASSIGN_LABEL,
  CATALOG_ITEM_SEARCH_MULTI_EDIT_LABEL,
  CATALOG_ITEM_NRI_CREATE_LABEL,
  NRI_REQUESTS_LABEL,
  NRI_REQUEST_LABEL,
  DEMAND_TEMPLATES_LABEL,
} from './constants'

interface GenericObjectIndexer {
  [key: string]: string
}

export const mfeLabels: GenericObjectIndexer = {
  CatalogDetails: CATALOG_DETAILS_PATH,
  CatalogDetailsLabel: CATALOG_DETAILS_LABEL,
  CatalogImageBulkUploadLabel: CATALOG_IMAGE_BULK_UPLOAD_LABEL,
  CatalogItemDetailsLabel: CATALOG_ITEM_DETAILS_LABEL,
  CatalogItemMultiEditLabel: CATALOG_ITEM_MULTI_EDIT_LABEL,
  CatalogItemSearchLabel: CATALOG_ITEM_SEARCH_LABEL,
  CatalogItemSearchAssignLabel: CATALOG_ITEM_SEARCH_ASSIGN_LABEL,
  CatalogItemSearchMultiEditLabel: CATALOG_ITEM_SEARCH_MULTI_EDIT_LABEL,
  CatalogItemNriCreateLabel: CATALOG_ITEM_NRI_CREATE_LABEL,
  CatalogParentLabel: CATALOG_PARENT_LABEL,
  CatalogUpdateListLabel: CATALOG_UPDATES_LABEL,
  ContractExpirationLabel: CONTRACT_EXPIRATION_LABEL,
  DemandParentLabel: DEMAND_PLANNING_PARENT_LABEL,
  DemandTemplatesLabel: DEMAND_TEMPLATES_LABEL,
  HomeLabel: HOME_LABEL,
  HznSourcingGroupsLabel: HZN_SOURCING_GROUPS_LABEL,
  HznSourcingGroupsMultiEditLabel: HZN_SOURCING_GROUPS_MULTI_EDIT_LABEL,
  IndirectItemParentLabel: INDIRECT_ITEM_PARENT_LABEL,
  IndirectItemSearchLabel: INDIRECT_ITEM_SEARCH_LABEL,
  UnspscLookupToolLabel: UNSPSC_LOOKUP_TOOL_LABEL,
  IndirectItemsMultiEditLabel: INDIRECT_ITEMS_MULTI_EDIT_LABEL,
  IndirectItemVariationsLabel: INDIRECT_ITEMS_VARIATIONS_LABEL,
  IndirectItemMultiDocDownloadLabel: INDIRECT_ITEMS_MULTI_DOC_DOWNLOAD_LABEL,
  IndirectItemReports: INDIRECT_ITEM_REPORTS,
  ItemAttrCompletionReportsLabel: ITEM_ATTR_REPORT_LABEL,
  NRIDetailsLabel: NRI_DETAILS_LABEL,
  NRICreateLabel: NRI_CREATE_LABEL,
  NriItemCreateLabel: NRI_ITEM_CREATE_LABEL,
  NriItemRequestsLabel: NRI_REQUESTS_LABEL,
  NriItemRequestLabel: NRI_REQUEST_LABEL,
  ProgramLabel: PROGRAM_LABEL,
  ProgramListLabel: PROGRAM_LIST_LABEL,
  ProgramSubLabel: PROGRAM_SUB_LABEL,
  Purchase: PURCHASE_PATH,
  PurchaseLabel: PURCHASE_LABEL,
  LocationsSearchLabel: LOCATIONS_SEARCH_LABEL,
  VaGridListLabel: VA_GRID_LIST_LABEL,
  VaEditLabel: VA_EDIT_LABEL,
  ZolPlannedBomLabel: ZOL_PLANNED_BOM_LABEL,
  ZolPublishedBomsLabel: ZOL_PUBLISHED_BOMS_LABEL,
}
