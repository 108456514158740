import { MicroFrontEnd } from 'properties-ui-common'
import apiConfig from '../../../apiConfig'

export const DemandTemplates = () => {
  return (
    <MicroFrontEnd
      name="DemandTemplates"
      src={apiConfig.microFrontEnds.DemandTemplates}
    />
  )
}

export default DemandTemplates
