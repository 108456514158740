import React, { useState } from 'react'
import {
  Button,
  Card,
  Divider,
  ExpandableSection,
  Form,
  Grid,
  Heading,
  Layout,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMeh, faSmile, faFrown } from '@fortawesome/free-regular-svg-icons'
import { useAuth } from 'properties-ui-common'
import apiConfig from '../../../../apiConfig'
import './FeedbackWidget.css'

interface FeedbackWidgetProps {
  onFeedback: Function
}

interface Rating {
  title: 'Feedback' | 'Issue' | 'Idea' | 'Comment'
  value: string | null
}

export const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({
  onFeedback,
}) => {
  const auth = useAuth()
  const makeToast = useToaster()
  const currPage = window.location.href

  const [feedbackValue, setFeedbackValue] = useState('')
  const [issueValue, setIssueValue] = useState('')
  const [ideaValue, setIdeaValue] = useState('')
  const [commentValue, setCommentValue] = useState('')

  const ratings: Rating[] = [
    { title: 'Issue', value: issueValue },
    { title: 'Idea', value: ideaValue },
    { title: 'Comment', value: commentValue },
    { title: 'Feedback', value: feedbackValue },
  ]

  const showToaster = () => {
    makeToast({
      headingText: 'Request Failed',
      type: 'error',
      autoHideDuration: 5000,
      message: 'Please try again later',
    })
  }

  const sendToSlack = async () => {
    try {
      await fetch(apiConfig.webhooks.slack, {
        headers: {
          authorization: `Bearer ${auth?.session?.accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          blocks: [
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `*User*: ${
                  auth?.session?.userInfo?.fullName ?? ''
                } \n*Email*: ${
                  auth?.session?.userInfo?.email ?? ''
                } \n*Path*: ${currPage} \n*Date:* ${Date()}`,
              },
            },
            {
              type: 'section',
              text: {
                type: 'mrkdwn',
                text: `${ratings
                  .filter((arr) => arr.value)
                  .map(
                    (feedback) => `*${feedback.title}* : _${feedback.value}_`,
                  )
                  .join('\n')}`,
              },
            },
          ],
        }),
      })
      onFeedback()
    } catch (err) {
      showToaster()
      console.error('Failed to post feedback:', err)
    }
  }

  return (
    <Layout.Rail className="railLayout">
      <Card className="hc-mr-normal hc-pa-normal hc-mt-normal">
        <Grid.Item align="left">
          <Grid.Container spacing="none">
            <Grid.Item className="hc-pa-normal">
              <Heading size={5} className="hc-pl-expanded">
                Provide Feedback
              </Heading>
            </Grid.Item>
            <Divider />
            <Grid.Item xs={12}>
              <ExpandableSection
                className="hc-mv-dense"
                padding="none"
                data-testid="feedback-widget-expandable-section-issue"
              >
                <p>Report an Issue</p>
                <ExpandableSection.Content>
                  <Form.Field
                    id="report-issue-textbox"
                    data-testid="issue"
                    className="hc-mt-normal"
                    type="textarea"
                    label="What's the issue?"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setIssueValue(event.target.value)
                    }
                    value={issueValue}
                  />
                </ExpandableSection.Content>
              </ExpandableSection>
            </Grid.Item>
            <Grid.Item xs={12}>
              <ExpandableSection
                className="hc-mv-dense"
                padding="none"
                data-testid="feedback-widget-expandable-section-idea"
              >
                <p>Share an Idea</p>
                <ExpandableSection.Content>
                  <Form.Field
                    id="share-idea-textbox"
                    className="hc-mt-normal"
                    data-testid="idea"
                    type="textarea"
                    label="What's your idea?"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setIdeaValue(event.target.value)
                    }
                    value={ideaValue}
                  />
                </ExpandableSection.Content>
              </ExpandableSection>
            </Grid.Item>
            <Grid.Item xs={12}>
              <ExpandableSection
                className="hc-mv-dense"
                padding="none"
                data-testid="feedback-widget-expandable-section-feedback"
              >
                <p>Overall Rating</p>
                <ExpandableSection.Content>
                  <Form.Field
                    id="feedback-radio"
                    className="hc-mt-normal"
                    type="radio"
                    value={feedbackValue}
                    onUpdate={(_id: any, value: string) =>
                      setFeedbackValue(value)
                    }
                    options={[
                      {
                        value: 'happy',
                        label: (
                          <FontAwesomeIcon
                            icon={faSmile as any}
                            size="2x"
                          ></FontAwesomeIcon>
                        ),
                      },
                      {
                        value: 'neutral',
                        label: (
                          <FontAwesomeIcon
                            icon={faMeh as any}
                            size="2x"
                          ></FontAwesomeIcon>
                        ),
                      },
                      {
                        value: 'unhappy',
                        label: (
                          <FontAwesomeIcon
                            icon={faFrown as any}
                            size="2x"
                          ></FontAwesomeIcon>
                        ),
                      },
                    ]}
                  />
                  <Form.Field
                    id="comment-textbox"
                    data-testid="comment"
                    type="textarea"
                    label="Comments*"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setCommentValue(event.target.value)
                    }
                    value={commentValue}
                  />
                </ExpandableSection.Content>
              </ExpandableSection>
            </Grid.Item>
          </Grid.Container>
          <Divider />
          <div className="hc-pa-normal">
            <Grid.Container spacing="dense" direction="row-reverse">
              <Grid.Item>
                <Button
                  size="dense"
                  disabled={
                    !feedbackValue && !commentValue && !issueValue && !ideaValue
                  }
                  onClick={sendToSlack}
                  type="primary"
                >
                  Submit
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  type="destructive"
                  size="dense"
                  onClick={() => onFeedback()}
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Grid.Item>
      </Card>
    </Layout.Rail>
  )
}
